/*
|--------------------------------------------------------------------------
| Users management features and utilities composable
|--------------------------------------------------------------------------
|
| This composable JS file contains all common properties and methods
| used in the different views or components for the Users management
| of the application.
*/

// Import Vue.js Framework functions
import { reactive } from 'vue'
import { computed } from 'vue'

// import own utility function library
import useCrudComp from './useCrudComp.js'
import useErrorsComp from '@/composables/useErrorsComp.js'
import useArrayUtilsComp from '@/composables/useArraysUtilsComp.js'

export default function useCustomersComp() {

    // define variable with the needed utils functions
    const useCrud = useCrudComp()
    const useErrors = useErrorsComp()
    const useArrayUtils = useArrayUtilsComp()

    // define the reactive variables
    const state = reactive({

        form: {
            body: {
                id: null,
                profession: '',
                civility: '',
                lastname: '',
                firstname: '',
                street: '',
                number: '',
                postcode: '',
                city: '',
                phone: '',
                mobile: '',
                email: '',
                active: '',
                info: '',
            },
            options: {
                civilities: [
                    { text: 'Madame', value: 'Madame' },
                    { text: 'Monsieur', value: 'Monsieur' },
                ],
                active: [
                    { text: '0 : Non', value: 0 },
                    { text: '1 : Oui', value: 1 },
                ],
            },
            status: null,
            resources: null,
            loading: true,

            response: {
                message: null,
                alert: null,
            },
        },

        pagination: {
            meta: {
                currentPage: 0,
                from: 0,
                lastPage: 0,
                perPage: 0,
                to: 0,
                total: 0,
                requestQtyPerPage: 0,
                requestedPage: 0,
            },
            links: {
                prev: null,
                next: null,
            },
        },



        checkedCustomers: [1],

        selectedCustomers : computed(() => {

            let length = state.checkedCustomers.length
            var selectedCustomersToShow = []

            for (var i = 0; i < length; i++) {
                selectedCustomersToShow[i] = state.checkedCustomers[i]
            }

            //state.form.resources.sort( compareString )
            //state.form.resources.sort( compareValue )

            return state.form.resources.filter(function(customer) {
                return selectedCustomersToShow.includes(customer.active)
            })

        })

    })



/* ************************************************************************ */
    const tableSort = reactive({
        asc: true,
        col: '',
    })

    // Function to sort Array of Resources
    function sortCustomers(byKey, type ) {

/*
        if ( tableSort.col === byKey ) {
            tableSort.asc = !tableSort.asc
        } else {
            tableSort.asc = true
            tableSort.col = byKey
        }
*/
        //useArrayUtils.sortArray( state.form.resources, byKey, type, tableSort.asc )
        useArrayUtils.sortArrayForTables( state.form.resources, byKey, type, tableSort )
    }


/* ************************************************************************ */



    // Flush all user's attributes
    function flushAttributes() {
        state.form.body.id = null
        state.form.body.profession = ''
        state.form.body.civility = ''
        state.form.body.lastname = ''
        state.form.body.firstname = ''
        state.form.body.street = ''
        state.form.body.number = ''
        state.form.body.postcode = ''
        state.form.body.city = ''
        state.form.body.phone = ''
        state.form.body.mobile = ''
        state.form.body.email = ''
        state.form.body.active = ''
        state.form.body.info = ''
    }

    // Flush all user's attributes
    function initAttributes() {
        state.form.body.active = 1


        // test data for dev purpose
    /*
        state.form.body.profession = 'Sales'
        state.form.body.civility = 'Monsieur'
        state.form.body.lastname = 'Auberson'
        state.form.body.firstname = 'Thierry'
        state.form.body.street = 'Rue des Saugettes'
        state.form.body.number = '15'
        state.form.body.postcode = '1417'
        state.form.body.city = 'Epautheyres'
        state.form.body.phone = '+41 12 123 45 67'
        state.form.body.mobile = ''
        state.form.body.email = 'tchery.auberson@gmail.com'
        state.form.body.active = ''
        state.form.body.info = ''
    */


    }

    // Get resources from backend
    function getCustomers() {

        state.form.loading = true

        return useCrud.getAllResources({
            url: 'customers'
        })
        .then((res) => {
            state.form.resources = res.data.data

            state.pagination.meta.total = res.data.meta.total
            state.pagination.meta.curentPage = res.data.meta.current_page
            state.pagination.meta.lastPage = res.data.meta.last_page
            state.pagination.meta.perPage = res.data.meta.per_page
            state.pagination.meta.to = res.data.meta.to
            state.pagination.meta.from = res.data.meta.from

            state.pagination.links.prev = res.data.links.prev
            state.pagination.links.next = res.data.links.next

            state.form.loading = false
            state.form.status = 'success'
        })
    }

    function getResourcesPaginate() {

        state.form.loading = true

        let nextPage = state.pagination.meta.requestedPage
        let nbPerPage = state.pagination.meta.requestQtyPerPage
        let requestUrl = 'customers/paginate/' + nbPerPage + '?page=' + nextPage

        return useCrud.getResourcesWithPagination({
            url: requestUrl
        })
        .then((res) => {
            state.form.resources = res.data.data

            state.pagination.meta.total = res.data.meta.total
            state.pagination.meta.curentPage = res.data.meta.current_page
            state.pagination.meta.lastPage = res.data.meta.last_page
            state.pagination.meta.perPage = res.data.meta.per_page
            state.pagination.meta.to = res.data.meta.to
            state.pagination.meta.from = res.data.meta.from

            state.pagination.links.prev = res.data.links.prev
            state.pagination.links.next = res.data.links.next


            state.form.loading = false
            state.form.status = 'success'
        })

    }

    function getCustomersFromArrayId(arrayId) {
        state.form.loading = true

        return useCrud.getFromArrayId({
            url: 'customers/getFromArrayId',
            resource: arrayId
        })
        .then((res) => {
            state.form.resources = res.data.data
            state.form.loading = false
            state.form.status = 'success'
        });
    }



    // Store new created resource to backend
    function searchResources(data) {

        useErrors.useResetErrors()
        state.form.status = 'saving'

        return useCrud.searchResources({
            url: 'customers/search/' + data,
            resource: data
        })
        .then((res) => {
            state.form.resources = res.data.resources
            state.form.status = 'success'
            return res.data.resources
        })
    }



    // Store new created resource to backend
/*
    function storeResource(resource) {

        state.form.status = 'saving'

        return new Promise((resolve, reject) => {

            axios({
                method: 'post',
                url: 'customers/',
                data: resource
            })
            .then((res) => {
                state.form.resources.push(res.data.resource)

                state.form.status = 'success'

                resolve(res)
            })
            .catch((err) => {
                state.form.status = 'error'
                reject(err)
            })

        }) // new Promise
    }
*/

    // Store new created resource to backend
    function storeResource() {

        useErrors.useResetErrors()
        state.form.status = 'saving'

        return useCrud.storeResource({
            url: 'customers',
            resource: state.form.body
        })
        .then((res) => {
            state.form.resources.push(res.data.resource)

            state.form.status = 'success'
            state.form.response.message = res.data.message
        })
    }


    function showResource(id) {

        state.form.loading = true

        return useCrud.showResource({
            url: 'customers/' + id,
            resource: state.form.body
        })
        .then((res) => {
            state.form.loading = false
            state.form.status = 'success'

            return res.data.resource
        });
    }

    // Update modified resource in backend
/*
    function updateResource(resource) {

        state.form.status = 'updating'

        //let id = state.form.body.id

        return new Promise((resolve, reject) => {

            axios({
                method: 'put',
                url: 'customers/' + resource.id,
                data: resource
            })
            .then((res) => {

                var index = state.form.resources.findIndex(x => x.id === res.data.resource.id)

                // if resource not existing in array
                if (index < 0) {
                    // add resource to array
                    state.form.resources.push(res.resource)
                } else {
                    // update resource in array
                    state.form.resources.splice(index, 1, res.data.resource)
                }

                state.form.status = 'success'

                resolve(res)
            })
            .catch((err) => {
                state.form.status = 'error'
                reject(err)
            })

        }) // new Promise
    }
*/

    // Update modified resource in backend
    function updateResource() {

        useErrors.useResetErrors()
        state.form.status = 'updating'

        let id = state.form.body.id

        return useCrud.updateResource({
            url: 'customers/' + id,
            resource: state.form.body
        })
        .then((res) => {
            var index = state.form.resources.findIndex(x => x.id === res.data.resource.id)

            // if resource not existing in array
            if (index < 0) {
                // add resource to array
                state.form.resources.push(res.data.resource)
            } else {
                // update resource in array
                state.form.resources.splice(index, 1, res.data.resource)
            }

            state.form.status = 'success'
            state.form.response.message = res.data.message
        })
    }

    // Delete resource in backend
/*
    function deleteResource(id) {

        state.form.status = 'deleting'

        return new Promise((resolve, reject) => {

            axios({
                method: 'delete',
                url: 'customers/' + id,
                data: id
            })
            .then((res) => {
                var index = state.form.resources.findIndex(x => x.id === id)
                state.form.resources.splice(index, 1)

                state.form.status = 'success'

                resolve(res)
            })
            .catch((err) => {
                state.form.status = 'error'
                reject(err)
            })
        }) // new Promise
    }
*/

    // Delete resource in backend
    function deleteResource(resource) {

        return useCrud.deleteResource({
            url: 'customers/' + resource.id,
            resource: resource.id
        })
        .then((res) => {
            var index = state.form.resources.findIndex(x => x.id === resource.id)
            state.form.resources.splice(index, 1)

            state.form.status = 'success'
            state.form.response.message = res.data.message
        })

    }


    return {
        state,
        useErrors,
        flushAttributes,
        initAttributes,
        getCustomers,
        getResourcesPaginate,
        getCustomersFromArrayId,
        searchResources,
        storeResource,
        showResource,
        updateResource,
        deleteResource,
        sortCustomers,
        tableSort,
        //...toRefs(state.form)
    }

}

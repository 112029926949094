/*
|--------------------------------------------------------------------------
| Users management features and utilities composable
|--------------------------------------------------------------------------
|
| This composable JS file contains all common properties and methods
| used in the different views or components for the Models management
| of the application.
*/

// Import Vue.js Framework functions
import { reactive } from 'vue'


// import own utility function library
import useCrudComp from './useCrudComp.js'
import useErrorsComp from '@/composables/useErrorsComp.js'


export default function useInvoicesComp() {


    // define variable with the needed utils functions
    const useCrud = useCrudComp()
    const useErrors = useErrorsComp()


    // define the reactive variables
    const state = reactive({

        form: {
            body: {
                id: null,
                customer_id: null,
                number: '',
                status: '',
                discount: 0,
                date: '',
                deadline: '',
            },
            status: null,
            resources: [],
            loading: true,

            response: {
                message: null,
                alert: null,
            },

        },

    })

    // Flush all user's attributes
    function flushAttributes() {
        state.form.body.id = null
        state.form.body.customer_id = null
        //state.form.body.number = null
        //state.form.body.status = null
        state.form.body.discount = 0
        //state.form.body.date = null
        //state.form.body.deadline = null
    }



    function getInvoicesFromArrayId(arrayId) {
        state.form.loading = true

        return useCrud.getFromArrayId({
            url: 'invoices/getFromArrayId',
            resource: arrayId
        })
        .then((res) => {
            state.form.resources = res.data.data
            state.form.loading = false
            state.form.status = 'success'

            return res.data.data
        });
    }


    function storeFromCustomerId(customer_id, data) {

        useErrors.useResetErrors()
        state.form.status = 'saving'

        return useCrud.storeResource({
            url: 'customers/' + customer_id + '/invoices',
            resource: data
        })
        .then((res) => {

            //console.log(res.data)

            if (state.form.resources === null) {
                state.form.resources = []
            }
            state.form.resources.push(res.data.invoice)

            state.form.status = 'success'

            flushAttributes()

            return res
        })
    }


    function updateResource() {

        useErrors.useResetErrors()
        state.form.status = 'updating'

        let id = state.form.body.id

        return useCrud.updateResource({
            url: 'invoices/' + id,
            resource: state.form.body
        })
        .then((res) => {
            var index = state.form.resources.findIndex(x => x.id === res.data.resource.id)

            // if resource not existing in array
            if (index < 0) {
                // add resource to array
                state.form.resources.push(res.data.resource)
            } else {
                // update resource in array
                state.form.resources.splice(index, 1, res.data.resource)
            }

            state.form.status = 'success'
            state.form.response.message = res.data.message

            flushAttributes()

            return res
        })
    }


    function deleteResource(id) {

        return useCrud.deleteResource({
            url: 'invoices/' + id,
            id: id
        })
        .then(() => {
            var index = state.form.resources.findIndex(x => x.id === id)
            state.form.resources.splice(index, 1)

            state.form.status = 'success'
        })


    }




    return {
        state,
        flushAttributes,
        useErrors,
        getInvoicesFromArrayId,
        storeFromCustomerId,
        updateResource,
        deleteResource,
    }
}
